"use client"
import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Close } from '@mui/icons-material';
import { AddActivity } from '../../queries/activity';
import { useSession } from 'next-auth/react';
import { LoadingButton } from '@mui/lab';
import { useMutation } from 'urql';
import { toast } from 'react-toastify';

import dynamic from 'next/dynamic'
const ReactQuill = dynamic(() => import("react-quill"), { ssr: false });
import 'react-quill/dist/quill.snow.css';

const styles = {
    box: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        boxShadow: 24,
        bgcolor: 'background.paper',
        borderRadius: '8px'
    },
    head: {
        padding: 10,
        borderBottom: '1px solid #f1f3f6',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    heading: {
        fontSize: '17px',
        fontWeight: 'bold'
    },
    textarea: {
        borderRadius: 4,
        backgroundColor: '#F3F8FF',
        margin: 15,
        border: '1px solid #f1f3f6',
        minHeight: 100,
        marginBottom: 0,
        padding: 10,
        width: '-webkit-fill-available',
        maxWidth: '93%'
    },
    button: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        width: '100%',
        padding: 2
    }
};

export default function AddNote({ open, handleClose }) {
    const [note, setNote] = React.useState("");
    const [loading, setLoading] = React.useState(false)
    const [result, addActivity] = useMutation(AddActivity);
    const [editor, showEditor] = React.useState(false)

    React.useEffect(() => {
        showEditor(true)
    })

    const handleSubmit = async () => {
        setLoading(true)
        const data = {
            summary: note,
            type: 'note',
            link: window.location.href
        }
        addActivity({ activityPayload: data }).then(res => {
            if (res.error) {
                toast.error(res.error.message);
            } else {
                toast.success("Note added successfully.")
                close();
            }
            setLoading(false)
        }).catch(err => {
            console.log(err)
            toast.error('There was an error.');
            setLoading(false)
        })
    };

    const close = () => {
        setNote(null)
        handleClose()
    }

    return <Modal
        open={open}
        onClose={close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
    >
        <Box sx={styles.box}>
            <Box style={styles.head}>
                <Typography sx={styles.heading} variant="h6" component="h2">
                    Add Notes
                </Typography>
                <Close sx={{ cursor: 'pointer' }} onClick={close} />
            </Box>
            <Box sx={{ height: 150 }}>
                {editor && <ReactQuill placeholder='Add your notes...' theme="snow" value={note} onChange={setNote} style={{ height: 100 }} />}
            </Box>
            <Box sx={styles.button}>
                <LoadingButton loading={loading} onClick={handleSubmit} variant="contained">Save Notes</LoadingButton>
            </Box>
        </Box>
    </Modal>
}