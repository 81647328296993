import React from 'react';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import styled from '@emotion/styled';
import { makeStyles } from 'tss-react/mui';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import { Clear } from '@mui/icons-material';
import { IconFilter } from '@tabler/icons';

const AcceptIconButton = styled(IconButton)`
  color: green;
`;

const RejectIconButton = styled(IconButton)`
  color: #ed6c6c;
`;

const CompareButton = styled(Button)`
  border-radius: 16px;
  color: black;
  border-color: rgb(190 183 183);
`;

export const ButtonWithArrowAndBackground = ({ text, extraClass }) => {
  const { classes } = useStyles();

  return (
    <div
      className={`${classes[extraClass] || ''} ${classes.btnWithArrow}`}
      style={{ display: 'flex', alignItems: 'center' }}
    >
      {text}
      <ArrowDropDown />
    </div>
  );
};

export const ButtonWithArrowAndBorder = ({
  text,
  className,
  extraClass,
  style = {},
  Icon
}) => {
  const { classes } = useStyles();

  return (
    <div
      className={`${className === 'small'
        ? classes.selectorBtnSmall
        : className === 'action'
          ? classes.action
          : classes.selector
        } ${classes[extraClass] || ''}`}
      style={{ display: 'flex', alignItems: 'center', ...style }}
    >
      {text}
      {Icon || <ArrowDropDown />}
    </div>
  );
};

export const NormalButton = ({ text, extraClass }) => {
  const { classes } = useStyles();

  return (
    <div className={`${classes[extraClass] || ''} ${classes.btnNormal}`}>
      {text}
    </div>
  );
};

export const RoundedButtonWithClearIcon = ({
  text,
  extraClass,
  clickedClear,
  style = {}
}) => {
  const { classes } = useStyles();

  return (
    <div
      className={`${classes[extraClass] || ''} ${classes.roundSelector}`}
      style={{
        display: 'flex',
        alignItems: 'center',
        margin: '4px 0px',
        fontSize: 13,
        ...style
      }}
    >
      {text}
      <Clear
        sx={{
          fontSize: '15px',
          marginLeft: 2,
          cursor: 'pointer',

          '&:hover': {
            color: 'red'
          }
        }}
        onClick={clickedClear}
      />
    </div>
  );
};

export const RoundedButton = ({
  text,
  extraClass,
  bgColor,
  textColor,
  borderColor,
  count
}) => {
  const { classes } = useStyles();

  return (
    <div
      className={`${classes[extraClass] || ''} ${classes.roundSelector}`}
      style={{
        display: 'flex',
        alignItems: 'center',
        width: 'fit-content',
        background: bgColor,
        borderColor: borderColor || bgColor,
        color: textColor || '#387ed0'
      }}
      data-cy={text}
    >
      {text}{' '}
      <span
        style={
          count
            ? {
              borderRadius: '24px',
              background: '#fff',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '18px',
              width: '18px',
              marginLeft: '3px',
              color: '#88ABD5',
              fontWeight: 700
            }
            : {}
        }
      >
        {count > 0 ? count : ''}
      </span>
    </div>
  );
};

export const ButtonsWithFilterIcon = ({ text }) => {
  return (
    <Button
      // variant="contained"
      size="large"
      endIcon={<IconFilter style={{ color: '#fff' }} />}
      style={{
        height: '40px',
        backgroundColor: '#387ED0',
        color: '#fff',
        borderRadius: '8px'
      }}
      data-cy={text}
    >
      {text}
    </Button>
  );
};

export const AnalysisRectengularButton = ({ text, extraClass, onClick }) => {
  const { classes } = useStyles();

  return (
    <div
      data-cy={text}
      className={`${classes[extraClass] || ''} ${classes.analysisButton}`}
    >
      {text}
      <Clear
        sx={{
          fontSize: '15px',
          marginLeft: 2,
          cursor: 'pointer',

          '&:hover': {
            color: 'red'
          }
        }}
        onClick={onClick}
      />
    </div>
  );
};

export const AnalysisCircularButton = ({
  text,
  extraClass,
  onClick,
  showClear
}) => {
  const { classes } = useStyles();

  return (
    <span
      className={`${classes[extraClass] || ''} ${classes.roundSelector}`}
      style={{
        background: '#397ed0',
        color: '#fff',
        display: 'flex',
        alignItems: 'center',
        justifyContent: !showClear ? 'center' : ''
      }}
      data-cy={text}
    >
      {text}
      {showClear ? (
        <Clear
          sx={{
            fontSize: '15px',
            marginLeft: 2,
            cursor: 'pointer',

            '&:hover': {
              color: 'red'
            }
          }}
          onClick={onClick}
        />
      ) : (
        ''
      )}
    </span>
  );
};

const useStyles = makeStyles()(theme => ({
  analysisButton: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '3px 10px 3px 10px',
    gap: '2px',
    background: ' #FFFFFF',
    /* light blue */
    border: ' 1px solid #88ABD5',
    borderRadius: '2px',
    height: '26px',
    color: '#88ABD5'
  },
  selector: {
    border: '1px solid #387ED0',
    padding: '5px 15px',
    borderRadius: 5,
    color: theme.palette.primary?.dark,
    fontWeight: '500',
    fontSize: '1rem'
  },
  action: {
    border: '1px solid #387ED0',
    padding: '6px 10px',
    borderRadius: 7,
    color: theme.palette.primary?.dark
    // fontWeight: '500',
    // fontSize: '1rem'
  },
  selectorBtnSmall: {
    border: '1px solid #387ED0',
    padding: '5px 15px',
    borderRadius: 5,
    color: theme.palette.primary?.dark,
    fontWeight: '500',
    fontSize: '1rem',
    height: '23px'
  },
  btnWithArrow: {
    backgroundColor: '#eff5ff',
    padding: '8px 15px',
    borderRadius: 5,
    color: theme.palette.primary?.dark,
    fontWeight: '500',
    fontSize: '1rem'
  },
  btnNormal: {
    backgroundColor: '#eff5ff',
    padding: '10px 15px',
    borderRadius: 5,
    color: theme.palette.primary?.dark,
    fontWeight: '500',
    fontSize: '1rem'
  },
  roundSelector: {
    border: '1px solid',
    padding: '2px 10px',
    borderRadius: 50,
    borderColor: theme.palette.primary?.dark,
    color: theme.palette.primary?.dark,
    fontWeight: '500',
    fontSize: '12px'
  },
  mx: {
    marginRight: '10px'
  },
  applyButton: {
    height: '30px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}));

export { AcceptIconButton, RejectIconButton, CompareButton };
