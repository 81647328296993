import { CircularProgress } from '@mui/material';
import React from 'react';

export const CircularLoaderRow  = () => <div style={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center', paddingTop: 10, paddingBottom: 10 }}><CircularLoader /></div>

const CircularLoader = () => {
  return (
    <div style={{display:'flex', alignItems:'center',}}>
      {' '}
      <CircularProgress size={20} />
    </div>
  );
};

export default CircularLoader;
