import * as React from 'react';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { InfoIcon } from '../../icons/icons';
import { InfoOutlined } from '@mui/icons-material';

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#fff',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: '12px',
    border: '1px solid #dadde9',
    padding: '0px'
  }
}));

export default function InformationTooltip({
  info,
  width = 21,
  height = 20,
  style = {},
  manualIcon=false
}) {
  return (
    <div style={{ cursor: 'pointer', ...style }}>
      <HtmlTooltip
        title={
          <React.Fragment>
            <Typography color="gray">{info}</Typography>
          </React.Fragment>
        }
        placement="bottom-end"
      >
        <span>
          {manualIcon ? <InfoOutlined style={{ fontSize: width }}  /> :<InfoIcon width={width} height={height} />}
        </span>
      </HtmlTooltip>
    </div>
  );
}
