import * as Urql from 'urql';

export const AddActivity = `
   mutation AddActivity($activityPayload: ActivityPayload) {
    addActivity(activityPayload: $activityPayload) {
      message
    }
  }
`;

export const ListActivity = `
   query ($page: Int, $size: Int) {
    listActivity(page: $page, size: $size) {
      id
      link
      type
      summary
      createdAt
    }
  }
`;

export function getActivityList(options) {
  return Urql.useQuery({ query: ListActivity, variables: options, requestPolicy: "network-only" });
}

export const UpdateActivity = `
   mutation ($updateActivityPayload: UpdateActivityPayload) {
    updateActivity(updateActivityPayload: $updateActivityPayload) {
      message
    }
  }
`;

export const DeleteActivityById = `
    mutation DeleteActivity($id: Int) {
        deleteActivity(id: $id) {
            success
        }
    } 
`;

export const ActivityDetails = `
  query ($id: Int) {
    getActivityDetails(id: $id) {
      type
      summary
      id
      link
      createdAt
    }
  }
`;