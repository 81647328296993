import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faLayerGroup,
  faShareSquare,
  faSave,
  faList,
  faNotesMedical
} from '@fortawesome/free-solid-svg-icons';
import NewTooltip from '../shared/Tooltip';
import { ButtonWithArrowAndBorder } from './Buttons';
import Link from 'next/link';
import { Grid, Typography } from '@mui/material';
import {
  AddNoteIcon,
  DetailsIcon,
  ExtractDataIcon,
  SaveIconAction,
  ShareIcon
} from '../../icons/icons';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';
import { checkRequiredResponsiveSizeUsingCssMediaQuery } from '../../utils/resposive.utils';
import { useRouter } from 'next/router';
import AddNote from '../activities/AddNote';
import { useMutation } from 'urql';
import { AddActivity } from '../../queries/activity';
import { toast } from 'react-toastify';
import { analysisPaths, analysisSelectedTemplates } from '../../store/constant';
import { nanoid } from 'nanoid';
import { encodeValue } from '../../utils/overview.utils';

const names = ['Share', 'Add Note', 'Save', 'Details', 'Extract Data'];

export default function Action(props) {
  const [open, setOpen] = React.useState(false);
  const router = useRouter();
  const [result, addActivity] = useMutation(AddActivity);
  const [openAddNote, setOpenAddNote] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const openNoteModal = () => {
    handleTooltipClose();
    setOpenAddNote(true);
  };

  const closeAddNote = () => {
    setOpenAddNote(false);
  };

  const newRouterPathForAnalysis = router?.pathname?.includes('/utilization')
    ? `${'/utilization'}-${
        router?.query?.medication
          ? 'medication'
          : router?.query?.theraputic
          ? 'therapeutic'
          : ''
      }`
    : router?.pathname;

  const openDetails = () => {
    const newTabId = nanoid();
    router.push(
      `/analysis/${newTabId}${
        analysisSelectedTemplates[newRouterPathForAnalysis]
          ? analysisSelectedTemplates[newRouterPathForAnalysis]
          : ''
      }${
        pathContainsFilters[router?.pathname]
          ? `&selectFilters=${encodeValue(
              pathContainsFilters[router?.pathname]
            )}`
          : ''
      }${props?.selectedDateFormat?`&selectedDateFormat=${props?.selectedDateFormat}`:''}`
    );
  };

  const handleSave = () => {
    setLoading(true);
    const data = {
      summary: document.title || 'Saved Page',
      type: 'page',
      link: window.location.href
    };
    addActivity({ activityPayload: data })
      .then(res => {
        if (res.error) {
          toast.error(res.error.message);
        } else {
          toast.success('Page saved successfully.');
          handleTooltipClose();
        }
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
        toast.error('There was an error. Please try again');
        setLoading(false);
      });
  };

  const openActivities = () => {
    router.push(`/my-activities`);
  };

  const options = [
    {
      title: 'Share',
      icon: faShareSquare,
      handleClick: () => console.log()
    },
    {
      title: 'Add Note',
      icon: faNotesMedical,
      handleClick: () => openNoteModal()
    },
    {
      title: 'Save',
      icon: faSave,
      handleClick: () => handleSave()
    },
    {
      title: 'Details',
      icon: faLayerGroup,
      handleClick: () => openDetails()
    },
    {
      title: 'My Activities',
      icon: faList,
      handleClick: () => openActivities()
    }
  ];

  const handleTooltipOpen = () => {
    setOpen(true);
  };
  const handleTooltipClose = () => {
    setOpen(false);
  };

  const pathContainsFilters = {
    [router.pathname]: props?.selectFilters ? props?.selectFilters : false
  };

  return (
    <div data-cy="actions">
      {props.gridView ? (
        <Grid container flex flexWrap={'wrap'}>
          {options.map((option, index) => (
            <MenuItem
              style={{ padding: '8px 10px', minWidth: '90px' }}
              onClick={option.handleClick}
              key={option.title}
              value={option.title}
            >
              <FontAwesomeIcon
                icon={option.icon}
                color={'#465571'}
                height="13px"
                width="13px"
              />
              <Typography sx={{ paddingLeft: 1, color: '#465571' }}>
                {option.title}
              </Typography>
            </MenuItem>
          ))}
        </Grid>
      ) : (
        <NewTooltip
          placement="bottom-end"
          handleTooltipClose={handleTooltipClose}
          handleTooltipOpen={handleTooltipOpen}
          open={open}
          title={
            <>
              {options.map((option, index) => (
                <MenuItem
                  disabled={loading}
                  style={{ padding: '8px 10px', minWidth: '150px' }}
                  onClick={option.handleClick}
                  key={option.title}
                  value={option.title}
                >
                  <FontAwesomeIcon
                    icon={option.icon}
                    color={'#465571'}
                    height="13px"
                    width="13px"
                  />
                  <Typography sx={{ paddingLeft: 1, color: '#465571' }}>
                    {option.title}
                  </Typography>
                </MenuItem>
              ))}
            </>
          }
        >
          <ButtonWithArrowAndBorder
            className={'action'}
            text="Action"
            style={{ width: '100%' }}
          />
        </NewTooltip>
      )}
      <AddNote open={openAddNote} handleClose={closeAddNote} />
    </div>
  );
}

export function ActionsWithoutButtons(props) {
  const matches =
    checkRequiredResponsiveSizeUsingCssMediaQuery('(max-width:550px)');

  const router = useRouter();
  const pathContainsFilters = {
    [router.pathname]: props?.selectFilters ? props?.selectFilters : false
  };

  const icons = [
    <ShareIcon />,
    <AddNoteIcon />,
    <SaveIconAction />,
    <DetailsIcon />,
    <ExtractDataIcon />
  ];

  const handleTooltipOpen = () => {
    setOpen(true);
  };
  const handleTooltipClose = () => {
    setOpen(false);
  };
  const handleChange = event => {
    const {
      target: { value }
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };

  const newRouterPathForAnalysis = router?.pathname?.includes('/utilization')
    ? `${'/utilization'}-${
        router?.query?.medication
          ? 'medication'
          : router?.query?.theraputic
          ? 'therapeutic'
          : ''
      }`
    : router?.pathname;

  const newTabId = nanoid();

  return (
    <Grid data-cy="actions" container>
      {names.map((name, index) => (
        <Grid key={name} item xs={4} sm={6} md={6} lg={6}>
          <MenuItem style={{ padding: '5px 15px' }} key={name} value={name}>
            <Link
              href={
                name === 'Details'
                  ? `/analysis/${newTabId}${
                      analysisSelectedTemplates[newRouterPathForAnalysis]
                        ? analysisSelectedTemplates[newRouterPathForAnalysis]
                        : ''
                    }${pathContainsFilters[router?.pathname]}`
                  : '#'
              }
              style={{ textDecoration: 'none' }}
            >
              <span style={{ display: 'flex', alignItems: 'center' }}>
                <span style={{ height: '18px', width: '18px' }}>
                  {icons[index]}
                </span>
                <span
                  style={{
                    marginLeft: 10,
                    color: '#465671'
                  }}
                >
                  {name}
                </span>
              </span>
            </Link>
          </MenuItem>
        </Grid>
      ))}
    </Grid>
  );
}

const useStyles = makeStyles()({
  customOutline: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#387ED0'
    }
  }
});
